import React, { useEffect } from "react";
import Head from "next/head";
import Cookie from "js-cookie";

import { getQueryParam } from "@kikoff/client-utils/src/dom";

import BlockRows from "@component/landing/sections/block_rows";
import CustomerVideo from "@component/landing/sections/customer_video";
import Hero from "@component/landing/sections/hero";
import IntroPromoBanner from "@component/landing/sections/intro_promo_banner";
import MembersHelped from "@component/landing/sections/members_helped";
import MoneyBackGuarantee from "@component/landing/sections/money_back_guarantee/money_back_guarantee";
import Publications from "@component/landing/sections/publications";
import QuestionsSection from "@component/landing/sections/questions";
import Quotes from "@component/landing/sections/quotes";
import Summary from "@component/landing/sections/summary";
import RobotsMeta from "@component/robots_meta";
import analytics from "@util/analytics";

function Homepage() {
  useEffect(() => {
    setTimeout(() => {
      const planType = getQueryParam("plan");
      if (planType) {
        Cookie.set("plan", planType, { expires: 1 });
      }
    }, 35000);
  }, []);

  useEffect(() => {
    analytics.only("Criteo", "Snapchat").convert("View Homepage");
  }, []);

  return (
    <div>
      <IntroPromoBanner />
      <Head>
        <title>Kikoff Credit Builder | Build Credit Safely & Responsibly</title>
        <meta
          name="description"
          content="Kikoff makes it easy to build credit fast with affordable monthly plans. No credit check, no interest, no hidden fees. Reports to all 3 major credit bureaus. Sign up in minutes."
        />
        <link rel="canonical" href="https://kikoff.com" />
        <RobotsMeta />
      </Head>
      <Hero />
      <Publications />
      <Summary />
      <BlockRows />
      <CustomerVideo />
      <MoneyBackGuarantee />
      <MembersHelped />
      <Quotes />
      <QuestionsSection />
    </div>
  );
}

export default Homepage;
